import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./styles.css";
import { Link, useLocation } from "react-router-dom";
import { rsaEnc } from "../../comman/decodeEncodeData";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loginUser, loginRapidUser } from "../../action/login";
import { sessonLoginUser } from "../../action/sessonLogin";
import { loginEncUser } from "../../action/loginEnc";
import Loader from "../../components/loader";
import { toast } from "react-toastify";
import { updateLocation } from "../../action/updateLocation";
import { Modal } from "react-bootstrap";

import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from "mic-check";
import { getCallDetails } from "../../action/azureCalling";
import {
  _getStorageValue,
  getUserId,
  _getStorageValueRapid,
} from "../../comman/localStorage";
import { ModalFooter } from "react-bootstrap";

const RapidWeb = (props) => {
  const history = useHistory();
  const search = useLocation().search;
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [rsaLoader, setRsaLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loginSuccessState, setLoginSuccessState] = useState(false);
  const [isMediaPermissionDenied, setIsMediaPermissionDenied] = useState(false);
  const [isLocationDenied, setIsLocationDenied] = useState(false);
  const [azure, setAzure] = useState(false);
  const [show, setShow] = useState(false);
  const [isSafari, setSafari] = useState(false);

  const successCallBackLoginEnc = (response) => {
    setRsaLoader(false);
    loginCallRsa(response.data.rsa);
    if (response.data.rsa) {
      triggerLogin(response.data.rsa);
    }
  };

  const loginCallRsa = (key) => {
    let url = window.location.href;

    let data = url.replace(/=/g, " =");
    let datatype = data.replace(/&/g, " =");
    let fields = datatype.split("=");
  };
  const triggerLogin = (rsaKey) => {
    if (username === "") {
      setLoginSuccessState(false);
    } else if (password === "") {
      setLoginSuccessState(false);
    } else {
      props.loginRapidUser(
        rsaEnc(username, rsaKey),
        rsaEnc(password, rsaKey),
        successLogin,
        failedLogin,
      );
    }
  };
  const successLogin = (response) => {
    setLoginSuccessState(true);
    setLoading(false);
  };
  const failedLogin = () => {
    setLoading(false);
  };

  useEffect(() => {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        setSafari(false);
      } else {
        setSafari(true);
      }
    }
    const isAzure = new URLSearchParams(search).get("azureVideoCall");
    if (!!isAzure && !!props?.callDataValue?.[0]?.call_status) {
      window.location.reload();
    } else if (!!isAzure) {
      setAzure(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    setUserName(new URLSearchParams(search).get("username"));
    setPassword(new URLSearchParams(search).get("password"));
    if (username !== "" && password !== "") {
      props.loginEncUser(successCallBackLoginEnc);
    }
  }, [username]);

  useEffect(() => {
    if (isLocationDenied || isMediaPermissionDenied) {
      setShow(true);
    }
  }, [isLocationDenied, isMediaPermissionDenied]);

  const onSuccessCallback = (data) => {
    setTimeout(() => {
      if (data.data[0].call_status == "Call has not been created") {
        toast.error("Call has not been created", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setLoading(false);
      } else {
        getCustomerLocation("updateUserLocation");
      }
    });
  };

  const onFailureCallback = () => {
    toast.error("Token is expired please try again", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const getCustomerLocation = (call) => {
    if (call == "handleRoom") {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          if (position.coords) {
            setIsMediaPermissionDenied(false);
            setIsLocationDenied(false);

            _getStorageValueRapid("USER_ID_RAPID").then((uId) => {
              let userID = uId;
              _getStorageValueRapid("USER_NAME").then((user) => {
                props.getCallDetails(
                  userID,
                  user,
                  onSuccessCallback,
                  onFailureCallback,
                );
              });
            });
          } else {
            setIsLocationDenied(true);
          }
        },
        function (error) {
          setIsLocationDenied(true);
          setLoading(false);
        },
      );
    } else if (call == "updateUserLocation") {
      const successCallbck = () => {
        setLoading(false);
        history.push("/azure-video");
      };
      navigator.geolocation.getCurrentPosition(function (position) {
        if (position.coords) {
          _getStorageValueRapid("USER_ID_RAPID").then((uId) => {
            let userID = uId;
            _getStorageValueRapid("USER_NAME").then((user) => {
              props.updateLocation(
                userID,
                user,
                position.coords.latitude,
                position.coords.longitude,
                successCallbck,
                () => {
                  setLoading(false);
                  toast.error("Failed to receive geo location...", {
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                },
              );
            });
          });
        }
      });
    }
  };

  const handleVideoRoom = () => {
    setLoading(true);
    setIsLocationDenied(false);
    setIsMediaPermissionDenied(false);
    requestMediaPermissions()
      .then(() => {
        if ("geolocation" in navigator) {
          getCustomerLocation("handleRoom");
        } else {
          setIsLocationDenied(true);
        }
      })
      .catch((err) => {
        setIsMediaPermissionDenied(true);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="main-home-container">
        <div className="rapid-header">
          <label>ICICI - RAPID</label>
        </div>
        <a href="chrome://settings/content" target="_blank"></a>
        {loading && <Loader />}
        {loginSuccessState || azure ? (
          <React.Fragment>
            <div className="sub-home-container">
              <div className="card-videoCall" onClick={() => handleVideoRoom()}>
                <div className="image-png">
                  <img
                    src={require("../../assets/images/video-camera.png")}
                    alt="dsd"
                  />
                </div>
                <p className="button-text">Connect</p>
              </div>

              <div
                style={{
                  marginTop: 30,
                  textDecoration: "underline",
                  textAlign: "center",
                  color: "blue",
                }}
                onClick={() => {
                  let url = window.location.href.replace("https", "rapid");
                  window.open(url);
                }}
              >
                Login with App
              </div>
              {(isMediaPermissionDenied || isLocationDenied) && (
                <div>
                  <p
                    style={{
                      fontSize: 15,
                      paddingTop: 10,
                      fontWeight: 800,
                      textAlign: "center",
                      color: "#F75D59",
                    }}
                  >
                    <div>For Android:</div>
                    <div>Please click</div>
                    <div>
                      {`more options (three dots) --> settings --> site settings --> enable camera, microphone & location permissions`}
                    </div>
                    <div style={{ paddingTop: 20 }}>For iOS:</div>
                    <div>Please click</div>
                    <div>
                      {`more options (`}
                      <span style={{ fontSize: 10 }}> A</span>A
                      {`)--> Website Settings --> enable camera, microphone & location permissions`}
                    </div>
                  </p>
                </div>
              )}
              {/* {isLocationDenied && (
                <div>
                  <p
                    style={{
                      fontSize: 15,
                      paddingTop: 10,
                      fontWeight: "normal",
                      textAlign: "center",
                      color: "#F75D59",
                    }}
                  >
                    Please grant
                    <span style={{ color: "#DC143C" }}>location</span> permission
                    in your browser settings to use this app.
                  </p>
                </div>
              )} */}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="sub-home-container">
              <p className="title-text">
                Verify the URL to check for valid login credentials.
              </p>
            </div>
          </React.Fragment>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isFetchingSesson: state.sessonLogin.isFetchingSesson,
  callDataValue: state.azureCalling.callDataValue,
  status: state.login.status,
  message: state.login.message,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCallDetails: getCallDetails,
      loginUser: loginUser,
      loginRapidUser: loginRapidUser,
      sessonLoginUser: sessonLoginUser,
      loginEncUser: loginEncUser,
      updateLocation: updateLocation,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RapidWeb);
